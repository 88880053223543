import React, { useState } from "react"

const ToggleSwitch = ({ toggleClassName }) => {
  const [isChecked, updateIsChecked] = useState(false)
  const sliderClass = `${toggleClassName}__slider`

  return (
    <div className={toggleClassName}>
      <input
        type="checkbox"
        value={isChecked}
        onChange={() => updateIsChecked(!isChecked)}
      />
      <div className={`${sliderClass}${isChecked ? "--checked" : null}`} />
    </div>
  )
}

export default ToggleSwitch
