import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
library.add(faCaretDown)

const makeOptions = arr =>
  arr.map((option, i) => (
    <option value={option} key={i}>
      {option}
    </option>
  ))

const Dropdown = ({
  options,
  onChange,
  label,
  name,
  value,
  classname,
  withDisabled,
  caretClassname,
  defaultValue = "",
  isDefaultSelected,
}) => (
  <div className={classname}>
    {label && <label>{label}</label>}
    <select
      name={name}
      onChange={onChange}
      value={value}
      defaultValue={isDefaultSelected ? defaultValue : null}
    >
      {withDisabled && (
        <option value="" disabled>
          Select the subject
        </option>
      )}
      {makeOptions(options)}
    </select>
    {caretClassname && (
      <FontAwesomeIcon className={caretClassname} icon="caret-down" />
    )}
  </div>
)

export default Dropdown
