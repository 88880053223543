import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Dropdown from "../components/atoms/dropdown"
import ToggleSwitch from "../../src/components/molecules/toggle-switch"

import { whatever, flagImage } from "../sass/settings.module.scss"

const languagesArray = ["🇬🇧 English", "🇫🇷 French"]

const Settings = ({ data }) => {
  const flags = data.allContentfulSettingsPage.edges[0].node.languagesFlags

  const [dropdownValue, setDropdownValue] = useState(undefined)

  useEffect(() => {
    const storageLanguage =
      localStorage.getItem("language") || languagesArray[0]
    setDropdownValue(storageLanguage)
  }, [])

  const onDropdownChange = e => {
    const selected = e.target.value
    localStorage.setItem("language", selected)
    setDropdownValue(selected)
  }

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | Settings" />
      <div>future page for settings</div>
      <div>Languages: </div>
      <Dropdown
        classname={""}
        label="Languages"
        name="language"
        options={languagesArray}
        value={dropdownValue}
        defaultValue={dropdownValue}
        isDefaultSelected={true}
        onChange={onDropdownChange}
      />
      {flags.map(({ title, fluid }) => (
        <Img key={title} className={flagImage} alt={title} fluid={fluid} />
      ))}
      <div
        className={whatever}
      >{`It will be need to be added to the documentation.
      When everything is functional we need to make the settings page accessible and uncomment in src/components/header.js

      The settings page will have:
      - A dropdown a flags clicker to select French (English being the default)
      - A theme on/off Toggle. Theme is per month and would depending on the month:
        - January: New year
        - February: Valentine
        - March: St Patrick’s Day
        - April: my Birthday / Easter
        - May: Spring, flowers
        - June: Sun and football (world cup)
        - July: fireworks blue red (USA, France national days)
        - August: ? Music festivals? Summer parties?
        - September: Falling leaves?
        - October: Halloween
        - November: Thanksgiving
        - December: Christmas/Santa

       Colors from src/sass/constants.scss should be changed depending on month.
       User settings for these 2 options of the settings page should be saved in a cookie
       As a result the cookies policy should be updated. No other info, such as personal info should be saved in cookies`}</div>

      <div>
        ToggleSwitch for Theme (need to style):
        <ToggleSwitch />
      </div>
    </Layout>
  )
}

export default Settings

export const query = graphql`
  query SettingsQuery {
    allContentfulSettingsPage {
      edges {
        node {
          languagesFlags {
            title
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
